import { Link, useForm } from '@inertiajs/react';
import {
  Button,
  Card,
  Checkbox,
  Group,
  Modal,
  Radio,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { useDisclosure } from '@mantine/hooks';
import { type SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiCheckboxCircleLine, RiErrorWarningLine } from 'react-icons/ri';

import { Field } from '@/components';
import { type Client } from '@/types';

interface Props {
  client: Client;
  action: string;
}

export function Form({ client, action }: Props) {
  const { t } = useTranslation();
  const [opened, { open, close }] = useDisclosure(false);
  const [consented, setConsented] = useState(client.consent);
  const [authorized, setAuthorized] = useState(client.consent);
  const { data, setData, errors, submit } = useForm(client);

  useEffect(() => {
    setData('consent', consented && authorized);
  }, [consented, authorized]);

  const save = (e: SyntheticEvent) => {
    e.preventDefault();
    submit(data.id != null ? 'patch' : 'post', action);
  };

  return (
    <>
      <form onSubmit={save}>
        <Card>
          <Card.Section>
            <Field label={t('client.column.email')} required>
              <TextInput
                required
                name="email"
                value={data.email}
                onChange={e => {
                  setData('email', e.target.value);
                }}
                error={errors.email}
              />
            </Field>
            <Field label={t('client.column.phone')}>
              <TextInput
                name="phone"
                value={data.phone}
                onChange={e => {
                  setData('phone', e.target.value);
                }}
                error={errors.phone}
              />
            </Field>
            <Field label={t('client.column.first_name')} required>
              <TextInput
                required
                name="first_name"
                value={data.first_name}
                onChange={e => {
                  setData('first_name', e.target.value);
                }}
                error={errors.first_name}
              />
            </Field>
            <Field label={t('client.column.last_name')} required>
              <TextInput
                required
                name="last_name"
                value={data.last_name}
                onChange={e => {
                  setData('last_name', e.target.value);
                }}
                error={errors.last_name}
              />
            </Field>
            <Field label={t('client.column.preferred_name')}>
              <TextInput
                name="preferred_name"
                value={data.preferred_name != null ? data.preferred_name : ''}
                onChange={e => {
                  setData('preferred_name', e.target.value);
                }}
                error={errors.preferred_name}
              />
            </Field>
            <Field label={t('client.column.date_of_birth')} required>
              <DateInput
                required
                name="date_of_birth"
                value={
                  data.date_of_birth != null
                    ? new Date(data.date_of_birth)
                    : null
                }
                onChange={value => {
                  if (value instanceof Date) {
                    setData(
                      'date_of_birth',
                      new Date(
                        `${value.toISOString().split('T')[0]}T00:00:00`,
                      ).toISOString(),
                    );
                  }
                }}
                error={errors.date_of_birth}
              />
            </Field>
            <Field label={t('client.column.sex')} required>
              <Radio.Group
                name="sex"
                value={data.sex}
                onChange={value => {
                  setData('sex', value);
                }}
                error={errors.sex}
              >
                <Group>
                  <Radio value="male" label="Male" />
                  <Radio value="female" label="Female" />
                  <Radio value="other" label="Other" />
                </Group>
              </Radio.Group>
            </Field>
            <Field label="Consent and authorization" required>
              <Button
                leftSection={
                  !consented || !authorized ? (
                    <RiErrorWarningLine />
                  ) : (
                    <RiCheckboxCircleLine />
                  )
                }
                color={!consented || !authorized ? 'red' : 'green'}
                variant="subtle"
                size="sm"
                onClick={open}
              >
                Review consent and authorization{' '}
                {(!consented || !authorized) && 'before continuing'}
              </Button>
            </Field>
          </Card.Section>
          <Group justify="end" p="md">
            <Button variant="outline" component={Link} href={action}>
              {t('common.action.back')}
            </Button>
            <Button type="submit" disabled={!consented || !authorized}>
              {t('common.action.save')}
            </Button>
          </Group>
        </Card>
      </form>

      <Modal opened={opened} onClose={close} size="xl">
        <Stack p="xl">
          <h3>{t('provider.consent.title')}</h3>
          <Checkbox
            size="md"
            label={t('provider.consent.consented.label')}
            description={
              <>
                {(
                  t('provider.consent.consented.description', {
                    returnObjects: true,
                  }) as string[]
                ).map((note, index) => (
                  <Text size="md" key={index}>
                    {index === 0 ? note : `- ${note}`}
                  </Text>
                ))}
              </>
            }
            checked={consented}
            onChange={e => setConsented(e.currentTarget.checked)}
          />
          <Checkbox
            size="md"
            label={t('provider.consent.authorized.label')}
            description={
              <>
                {(
                  t('provider.consent.authorized.description', {
                    returnObjects: true,
                  }) as string[]
                ).map((line, index, array) => (
                  <Text size="md" key={index}>
                    {index === array.length - 1 ? line : `- ${line}`}
                  </Text>
                ))}
              </>
            }
            checked={authorized}
            onChange={e => setAuthorized(e.currentTarget.checked)}
          />
          <Stack gap="xs">
            <Text size="sm">{t('provider.consent.note.title')}</Text>
            {(
              t('provider.consent.note.notes', {
                returnObjects: true,
              }) as string[]
            ).map((note, index) => (
              <Text size="sm" key={index}>
                {note}
              </Text>
            ))}
          </Stack>
        </Stack>
        <Group justify="end" p="md">
          <Button onClick={close}>{t('common.action.confirm')}</Button>
        </Group>
      </Modal>
    </>
  );
}
