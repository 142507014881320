interface PasswordValidation {
  isPasswordValid: boolean;
  passwordRequirements: {
    requirement: string;
    message: string;
    pass: boolean;
  }[];
}

const defaultPasswordRequirements = [
  {
    requirement: 'length',
    message: 'Minimum 10 characters',
    pass: false,
  },
  {
    requirement: 'uppercase',
    message: 'Use an uppercase letter',
    pass: false,
  },
  {
    requirement: 'symbol',
    message: 'Use a symbol',
    pass: false,
  },
  {
    requirement: 'number',
    message: 'Use a number',
    pass: false,
  },
];

const NOT_FOUND = -1;
const FOUND = 0;

const LENGTH = 10;
const UPPERCASE_REG_EXP = /[A-Z]/;
const SYMBOL_REG_EXP = /(?=.*[!@#$%^&*])/;
const NUMBER_REG_EXP = /[0-9]/;

export const validatePassword = (password: string): PasswordValidation => {
  const passwordRequirements = JSON.parse(
    JSON.stringify(defaultPasswordRequirements),
  );

  if (password.length < LENGTH) {
    passwordRequirements[0].pass = false;
  } else if (password.length >= LENGTH) {
    passwordRequirements[0].pass = true;
  }

  if (password.search(UPPERCASE_REG_EXP) === -1) {
    passwordRequirements[1].pass = false;
  } else if (password.search(UPPERCASE_REG_EXP) >= 0) {
    passwordRequirements[1].pass = true;
  }

  if (password.search(SYMBOL_REG_EXP) === NOT_FOUND) {
    passwordRequirements[2].pass = false;
  } else if (password.search(SYMBOL_REG_EXP) >= FOUND) {
    passwordRequirements[2].pass = true;
  }

  if (password.search(NUMBER_REG_EXP) === NOT_FOUND) {
    passwordRequirements[3].pass = false;
  } else if (password.search(NUMBER_REG_EXP) >= FOUND) {
    passwordRequirements[3].pass = true;
  }

  if (
    password.length >= LENGTH &&
    password.search(UPPERCASE_REG_EXP) >= FOUND &&
    password.search(SYMBOL_REG_EXP) >= FOUND &&
    password.search(NUMBER_REG_EXP) >= FOUND
  ) {
    return {
      isPasswordValid: true,
      passwordRequirements,
    };
  }

  return {
    isPasswordValid: false,
    passwordRequirements,
  };
};
