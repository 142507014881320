import {
  Alert,
  Button,
  Card,
  PasswordInput,
  Space,
  Stack,
  Text,
} from '@mantine/core';
import type { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { PasswordRequirements } from '@/components';
import { useForm, useQueryParams, useValidatePassword } from '@/hooks';
import type { UserType } from '@/types';

interface Props {
  resource_name: UserType;
  paths: {
    invitation_path: string;
  };
}

function Edit({ resource_name: resourceName, paths }: Props) {
  const { t } = useTranslation();

  const queryParams = useQueryParams();

  const { getPath, setPath, getError, put, errors } = useForm<
    Record<
      UserType,
      {
        password: string;
        password_confirmation: string;
        invitation_token: string;
      }
    >
  >({
    [resourceName]: {
      invitation_token: queryParams.get('invitation_token') ?? '',
    },
  });

  const { isPasswordValid, passwordRequirements } = useValidatePassword(
    getPath(`${resourceName}.password`, ''),
  );

  const save = (e: SyntheticEvent) => {
    e.preventDefault();
    put(paths.invitation_path);
  };
  return (
    <form onSubmit={save}>
      <Card padding="xl">
        <Text size="xl" fw={500} mb="xl">
          {t('invitation.edit.title')}
        </Text>
        <Stack>
          {getError(`${resourceName}.invitation_token`) !== undefined && (
            <Alert color="red">{t('invitation.edit.invalid_token')}</Alert>
          )}
          <PasswordInput
            placeholder={t('password.edit.password')}
            name="password"
            value={getPath(`${resourceName}.password`, '')}
            onChange={e => setPath(`${resourceName}.password`, e.target.value)}
            error={errors.password}
          />
          <PasswordInput
            placeholder={t('password.edit.password_confirmation')}
            name="password_confirmation"
            value={getPath(`${resourceName}.password_confirmation`, '')}
            onChange={e =>
              setPath(`${resourceName}.password_confirmation`, e.target.value)
            }
            error={errors.password_confirmation}
          />
          <PasswordRequirements passwordRequirements={passwordRequirements} />
        </Stack>
        <Space h="xl" />
        <div>
          <Button type="submit" disabled={!isPasswordValid}>
            {t('invitation.edit.accept')}
          </Button>
        </div>
      </Card>
    </form>
  );
}

export default Edit;
