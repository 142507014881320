import { Link, useForm } from '@inertiajs/react';
import {
  Button,
  Card,
  Group,
  Radio,
  Select,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import type { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Field } from '@/components';
import type { Client, Site } from '@/types';
import { formatDate } from '@/utils';

interface Props {
  client: Client;
  sites: Site[];
}

export function Form({ client, sites }: Props) {
  const { t } = useTranslation();

  const { data, setData, submit, errors } = useForm(client);
  const save = (e: SyntheticEvent) => {
    e.preventDefault();
    submit(
      client.identifier != null ? 'patch' : 'post',
      `/admin/clients/${client.identifier ?? ''}`,
    );
  };

  return (
    <form onSubmit={save}>
      <Stack gap="xl">
        <Card>
          <Card.Section>
            <Field label={t('site.singular')}>
              <Select
                name="site_id"
                value={data.site_id?.toString()}
                onChange={value => {
                  setData('site_id', value as string);
                }}
                data={sites.map(site => ({
                  value: site.id.toString(),
                  label: site.description,
                }))}
                error={errors.site_id}
              />
            </Field>
          </Card.Section>
        </Card>
        {data.site_id != null && (
          <Stack>
            <Card>
              <Card.Section>
                <Field label={t('user.column.first_name')} required>
                  <TextInput
                    required
                    value={data.first_name}
                    onChange={e => {
                      setData('first_name', e.target.value);
                    }}
                    error={errors.first_name}
                  />
                </Field>

                <Field label={t('user.column.last_name')} required>
                  <TextInput
                    required
                    value={data.last_name}
                    onChange={e => {
                      setData('last_name', e.target.value);
                    }}
                    error={errors.last_name}
                  />
                </Field>

                <Field label={t('client.column.date_of_birth')}>
                  <DateInput
                    name="date_of_birth"
                    value={
                      data.date_of_birth != null
                        ? new Date(data.date_of_birth)
                        : null
                    }
                    onChange={value => {
                      if (value instanceof Date) {
                        setData(
                          'date_of_birth',
                          new Date(
                            `${value.toISOString().split('T')[0]}T00:00:00`,
                          ).toISOString(),
                        );
                      }
                    }}
                    error={errors.date_of_birth}
                  />
                </Field>

                <Field label={t('client.column.sex')}>
                  <Radio.Group
                    name="sex"
                    value={data.sex}
                    onChange={value => {
                      setData('sex', value);
                    }}
                    error={errors.sex}
                  >
                    <Stack>
                      <Radio value="male" label={t('client.sex.male')} />
                      <Radio value="female" label={t('client.sex.female')} />
                      <Radio value="other" label={t('client.sex.other')} />
                    </Stack>
                  </Radio.Group>
                </Field>
              </Card.Section>
            </Card>

            <Card>
              <Card.Section>
                <Field label={t('user.column.email')} required>
                  <TextInput
                    required
                    value={data.email}
                    onChange={e => {
                      setData('email', e.target.value);
                    }}
                    error={errors.email}
                  />
                </Field>
                <Field label={t('user.column.email_confirmation')} required>
                  <TextInput
                    required
                    value={data.email_confirmation}
                    onChange={e => {
                      setData('email_confirmation', e.target.value);
                    }}
                    error={errors.email}
                  />
                </Field>
                <Field label={t('client.column.phone')}>
                  <TextInput
                    value={data.phone}
                    onChange={e => {
                      setData('phone', e.target.value);
                    }}
                    error={errors.phone}
                  />
                </Field>

                <Field label={t('client.column.share_with_provider')}>
                  <Radio.Group
                    name="share_with_provider"
                    value={data.share_with_provider ? 'true' : 'false'}
                    onChange={value => {
                      setData('share_with_provider', value === 'true');
                    }}
                    error={errors.share_with_provider}
                  >
                    <Stack>
                      <Radio value="true" label="True" />
                      <Radio value="false" label="False" />
                    </Stack>
                  </Radio.Group>
                </Field>

                <Field label={t('client.column.beta_features_enabled')}>
                  <Radio.Group
                    name="beta_features_enabled"
                    value={data.beta_features_enabled ? 'true' : 'false'}
                    onChange={value => {
                      setData('beta_features_enabled', value === 'true');
                    }}
                    error={errors.beta_features_enabled}
                  >
                    <Stack>
                      <Radio value="true" label="True" />
                      <Radio value="false" label="False" />
                    </Stack>
                  </Radio.Group>
                </Field>

                {client.identifier && (
                  <Field label={t('client.column.invitation')}>
                    {client.invitation_accepted_at !== null ? (
                      <Text>
                        Accepted on {formatDate(client.invitation_accepted_at)}
                      </Text>
                    ) : (
                      <>
                        {client.invitation_sent_at !== null && (
                          <Text>
                            Invitation sent on{' '}
                            {formatDate(client.invitation_sent_at)}
                          </Text>
                        )}
                        <Button
                          component={Link}
                          type="button"
                          method="post"
                          href={`/admin/clients/${client.id}/invites`}
                          onClick={e => {
                            if (
                              !confirm(
                                t('provider.confirm.resend_invite', {
                                  name: `${client.first_name} ${client.last_name}`,
                                }),
                              )
                            ) {
                              e.preventDefault();
                            }
                          }}
                        >
                          {t('client.actions.resend_invitation')}
                        </Button>
                      </>
                    )}
                  </Field>
                )}

                <Group p="md" justify="end">
                  <Button type="submit">{t('common.action.save')}</Button>
                </Group>
              </Card.Section>
            </Card>
          </Stack>
        )}
      </Stack>
    </form>
  );
}
