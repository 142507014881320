import { useForm, usePage } from '@inertiajs/react';
import { Group, Select } from '@mantine/core';
import type { ReactNode } from 'react';
import { useEffect, useRef } from 'react';

import { SearchInput } from '@/components';
import { useQueryParams } from '@/hooks';

import classes from './Toolbar.module.css';

interface Props {
  filters?: ReactNode;
  actions?: ReactNode;
  searchCols?: string[];
  sortCols?: string[];
}

export function Toolbar({ filters, actions, searchCols, sortCols }: Props) {
  const { url } = usePage();

  const queryParams = useQueryParams();

  const cols = searchCols != null ? `${searchCols.join('_or_')}_cont` : '';

  const { data, setData, get } = useForm({
    page: '',
    per: queryParams.get('per') ?? '',
    q: queryParams.get(searchCols != null ? `q[${cols}]` : 'q') ?? '',
    s: queryParams.get('s') ?? '',
  });

  const rendered = useRef(false);

  useEffect(() => {
    if (!rendered.current) {
      rendered.current = true;
      return;
    }
    get(url);
  }, [data.per, data.q, data.s]);

  const handleSearch = (value: string) => {
    const query = value?.trim();
    let searchQuery;
    if (query != null && query.length > 0) {
      if (searchCols != null && searchCols.length > 0) {
        searchQuery = { [`${searchCols.join('_or_')}_cont`]: query };
      } else {
        searchQuery = query;
      }
    } else {
      searchQuery = '';
    }
    setData('q', searchQuery);
  };

  const handleSort = (value: string | null) => {
    setData('s', value ?? '');
  };

  const toSentenceCase = (columnName: string): string => {
    return columnName
      .replace(/_/g, ' ')
      .replace(/([A-Z])/g, ' $1')
      .toLowerCase()
      .replace(/^./, str => str.toUpperCase());
  };

  return (
    <Group justify="space-between" p="md" className={classes.root}>
      <Group>
        <Select
          w={90}
          value={data.per}
          data={['25', '50', '100']}
          onChange={value => {
            setData('per', value);
          }}
          name="per"
        />
        {filters}
        <Select
          placeholder="Order by"
          w={120}
          value={data.s}
          data={sortCols?.map(col => ({
            value: `${col} asc`,
            label: toSentenceCase(col),
          }))}
          onChange={handleSort}
          name="s"
        />
      </Group>
      <Group>
        {actions != null && <div className="actions">{actions}</div>}
        <SearchInput value={data.q} onSubmit={handleSearch} name="q" />
      </Group>
    </Group>
  );
}
