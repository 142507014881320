import { Link, usePage } from '@inertiajs/react';
import { Anchor, Box, Group, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { FaChevronLeft } from 'react-icons/fa';

import type { Client } from '@/types';

import classes from './_SubHeader.module.css';

interface Props {
  client: Client;
}

export function SubHeader({ client }: Props) {
  const { t } = useTranslation();

  const page = usePage();

  return (
    <Group h="95px" gap="lg" align="end">
      <Box>
        <Anchor component={Link} fw="bold" href="/provider/clients">
          <FaChevronLeft size={12} /> {t('patient.action.all_patients')}
        </Anchor>
        <Text size="24px" fw={700} mb="lg" mt="xs">
          {client.first_name} {client.last_name}
        </Text>
      </Box>
      <Group gap="xs">
        <Link
          href={`/provider/clients/${client.id}`}
          className={classes.mainLink}
          data-active={page.url.endsWith(client.id.toString())}
        >
          {t('profile.singular')}
        </Link>
        <Link
          href={`/provider/clients/${client.id}/health_scores`}
          className={classes.mainLink}
          data-active={page.url.endsWith('health_scores')}
        >
          {t('health_score.plural')}
        </Link>
        <Link
          href={`/provider/clients/${client.id}/biomarker_lookup`}
          className={classes.mainLink}
          data-active={page.url.endsWith('biomarker_lookup')}
        >
          {t('biomarker.lookup')}
        </Link>
        <Link
          href={`/provider/clients/${client.id}/action_plan`}
          className={classes.mainLink}
          data-active={page.url.endsWith('action_plan')}
        >
          {t('action_plan.singular')}
        </Link>
      </Group>
    </Group>
  );
}
