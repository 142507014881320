import { Box, Card, Flex, Group, Text } from '@mantine/core';
import { FaCircle } from 'react-icons/fa';

import type { Action } from '@/types';
import { getColor } from '@/utils';

import classes from './_NavGroup.module.css';

interface Props {
  recommendations: Action[];
  value: Action | null;
  onChange: (value: Action) => void;
}

export function NavGroup({ recommendations, value, onChange }: Props) {
  const maxVisible = 8;
  if (recommendations) {
    return (
      <Box p="-1rem" mt="xs" pr="sm">
        <Flex direction={{ base: 'row', sm: 'column' }} gap="xl" wrap="nowrap">
          {recommendations.map((action, index) => (
            <Card
              p="md"
              withBorder
              radius={0}
              key={index}
              onClick={() => {
                onChange(action);
              }}
              className={`${classes.navLink} ${index === 0 ? classes.firstCard : ''} ${index === recommendations.length - 1 ? classes.lastCard : ''}`}
              data-active={action.id === value?.id}
              data-action={action.action}
              style={{ minWidth: '200px' }}
            >
              <Text
                size="sm"
                fw={500}
                c={action?.action === 'avoid' ? 'red' : 'blue'}
                tt="uppercase"
              >
                {action.action}
              </Text>
              <Group justify="space-between">
                <Text size="h5" tt="capitalize">
                  {action.name}
                </Text>
                <Group gap={4} ml="auto">
                  {[...action.target_health_areas, ...action.target_biomarkers]
                    .sort((a, b) => {
                      if (a.color === 'red' && b.color === 'yellow') return -1;
                      if (a.color === 'yellow' && b.color === 'red') return 1;
                      return 0;
                    })
                    .slice(0, maxVisible)
                    .map((area, index) => (
                      <Box key={index} style={{ flexShrink: 0 }}>
                        <FaCircle size={9} color={getColor(area.color)} />
                      </Box>
                    ))}
                  {action.target_health_areas.length +
                    action.target_biomarkers.length >
                    maxVisible && (
                    <Text size="xs" c="gray">
                      +
                      {action.target_health_areas.length +
                        action.target_biomarkers.length -
                        maxVisible}
                    </Text>
                  )}
                </Group>
              </Group>
            </Card>
          ))}
        </Flex>
      </Box>
    );
  }
}
