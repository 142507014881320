import { Link, usePage } from '@inertiajs/react';
import {
  ActionIcon,
  AppShell,
  Box,
  Container,
  Group,
  ScrollArea,
} from '@mantine/core';
import { Notifications, notifications } from '@mantine/notifications';
import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { MdLineAxis } from 'react-icons/md';
import { RiBarChartLine, RiDropLine, RiMenuLine } from 'react-icons/ri';

import { useFlash } from '@/hooks';
import {
  client_actions_path,
  client_reports_root_path,
  client_tests_index_path,
} from '@/routes';

import { Logo } from './_Logo';
import { UserMenu } from './_UserMenu';
import classes from './Client.module.css';

const links = [
  {
    link: client_tests_index_path(),
    label: 'Take a test',
    icon: RiDropLine,
  },
  {
    link: client_reports_root_path(),
    label: 'Your results',
    icon: RiBarChartLine,
  },
  {
    link: client_actions_path(),
    label: 'Action plan',
    icon: MdLineAxis,
  },
];

interface Props {
  page: ReactNode;
}

export function Client({ page }: Props) {
  const { url } = usePage();

  const [collapsed, setCollapsed] = useState(true);

  const flash = useFlash();

  useEffect(() => {
    if (flash?.alert != null) {
      notifications.show({
        message: flash.alert,
        color: 'yellow',
      });
    }
  }, [flash?.alert]);

  useEffect(() => {
    if (flash?.error != null) {
      notifications.show({
        message: flash.error,
        color: 'red',
      });
    }
  }, [flash?.error]);

  useEffect(() => {
    if (flash?.notice != null) {
      notifications.show({
        message: flash.notice,
        color: 'green',
      });
    }
  }, [flash?.notice]);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  return (
    <AppShell
      className={classes.root}
      header={{ height: 60 }}
      navbar={{
        width: 300,
        breakpoint: 'sm',
        collapsed: { mobile: collapsed },
      }}
    >
      <Notifications />
      <AppShell.Header>
        <Container fluid>
          <Group justify="space-between">
            <Logo />
            <Group gap="xs">
              <UserMenu root="/clients" />
              <ActionIcon
                hiddenFrom="sm"
                variant="light"
                size="md"
                radius="xl"
                onClick={() => {
                  toggle();
                }}
              >
                <RiMenuLine />
              </ActionIcon>
            </Group>
          </Group>
        </Container>
      </AppShell.Header>
      <AppShell.Navbar>
        <ScrollArea className={classes.links}>
          <div className={classes.linksInner}>
            {links.map(item => (
              <Link
                className={classes.link}
                data-active={url.includes(item.link) || undefined}
                onClick={() => {
                  setCollapsed(true);
                }}
                href={item.link}
                key={item.label}
              >
                <item.icon className={classes.linkIcon} />
                <span>{item.label}</span>
              </Link>
            ))}
          </div>
        </ScrollArea>
      </AppShell.Navbar>
      <AppShell.Main>
        <Box py="xl" px={{ sm: 'xl' }}>
          {page}
        </Box>
      </AppShell.Main>
    </AppShell>
  );
}
